import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import SesManagementTopItem from './ses-managementTopItem'

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 80px;
  .p-section-inner {
    max-width: 1200px;
    padding: 80px 0 80px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .catch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: fit-content;
    .catch-top {
      color: #1d4a5d;
      font-weight: bold;
      font-size: 34px;
      width: fit-content;
      .balloon {
        width: fit-content;
        background-color: #176a7e;
        padding: 0 60px;
        border-radius: 8px;
        color: #fff;
        position: relative;
        margin-bottom: 30px;
        .triangle {
          position: absolute;
          bottom: -14px;
          left: 45%;
          right: 0;
          content: '';
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid #176a7e;
          margin-top: -5vw;
        }
      }
    }
    .catch-image {
      position: absolute;
      z-index: 2;
      top: 0px;
      right: -75px;
      height: 120px;
      width: 120px;
      img {
        height: inherit;
        width: inherit;
      }
    }
  }
  .item-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  ${BreakPoints.xxLarge} {
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.medium} {
    .catch {
      .catch-image {
        width: 70px;
        height: 70px;
        right: -30px;
      }
      .catch-top {
        font-size: 24px;
      }
    }
  }
`

/**
 * 案件管理機能紹介ページ-最上部キャッチ
 * @constructor
 */
const SesManagementTop = () => (
  <Wrapper>
    <div className="p-section-inner">
      {/** キャッチ部分 */}
      <div className="catch">
        <div className="catch-image">
          <img
            src="/images/official/function/sesManagementFunctionCatch.png"
            alt="キンクラ_SES案件管理機能"
          />
        </div>
        <div className="catch-top">
          <p>膨大な量の案件情報を</p>
          <div className="balloon">
            <p>クラウド上で一元管理</p>
            <div className="triangle" />
          </div>
        </div>
        <div className="catch-bottom">
          <p>
            契約期間や単価情報などSES案件に関する様々なデータ
            <br />
            をキンクラ上で容易に管理することができます。
          </p>
        </div>
      </div>

      {/** 紹介アイテム */}
      <div className="item-wrap">
        <SesManagementTopItem
          label={'契約内容と請求情報を\n一元管理'}
          img="sesManagementFunctionTop01"
        />
        <SesManagementTopItem
          label={'顧客別の売上額も\nグラフ化して見やすい'}
          img="sesManagementFunctionTop02"
        />
        <SesManagementTopItem
          label={'契約更新のアラートで\n確認漏れを防止'}
          img="sesManagementFunctionTop03"
        />
        <SesManagementTopItem
          label={'業務負荷を軽減し\n営業効率を向上'}
          img="sesManagementFunctionTop04"
        />
      </div>
    </div>
  </Wrapper>
)

export default SesManagementTop
