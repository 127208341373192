import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  margin: 30px;
  flex-direction: column;
  width: 400px;
  height: 480px;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  // overflow: hidden;
  box-shadow: 0px 2px 10px #ababab;
  position: relative;

  .image-area {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 250px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .text-area {
    flex: 1;
    padding: 35px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .label-area {
    width: fit-content;
  }

  h3 {
    font-size: 40px;
    color: #313a38;
    position: relative;
    margin-bottom: 40px;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #51d3c7;
      bottom: -10px;
      left: 0px;
    }
  }

  .icon-wrap {
    height: 120px;
    width: 120px;
    position: absolute;
    top: -45px;
    right: -30px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  ${BreakPoints.xLarge} {
    height: 360px;
    width: 300px;
    margin: 10px;

    .image-area {
      height: 178px;
    }

    .text-area {
      height: 115px;
      padding: 20px 25px;

      p {
        font-size: 14px;
      }
    }

    h3 {
      font-size: 20px;
      margin-bottom: 25px;
    }

    .icon-wrap {
      height: 90px;
      width: 90px;
      top: -30px;
      right: -20px;
    }
  }

  ${BreakPoints.smallest} {
  }
`

type Props = {
  /** タイトル */
  label: string
  /** 内容 */
  children: ReactNode
  /** スクリーンショット */
  img: string
  /** アイコン */
  icon: string
}

/**
 * 案件管理機能紹介ページ-主な機能_機能1つ分コンポーネント
 * @constructor
 */

const SesManagementFunctionArea = (props: Props): JSX.Element => {
  const { children, label, img, icon = '' } = props

  return (
    <Wrapper>
      <div className="icon-wrap">
        <img
          src={`/images/official/function/${icon}.png`}
          alt="キンクラ_SES案件管理機能"
        />
      </div>
      <div className="image-area">
        <img
          src={`/images/official/function/${img}.png`}
          alt="キンクラ_SES案件管理機能"
        />
      </div>
      <div className="text-area">
        <div className="label-area">
          <h3>{label}</h3>
        </div>
        <p>{children}</p>
      </div>
    </Wrapper>
  )
}

export default SesManagementFunctionArea
